<template>
  <div class="tile">
    <div class="tile is-parent">
      <article class="tile is-child detail-page-tile">
        <div class="columns">
          <div class="column">
            <div class="category-name is-size-4 has-text-weight-semibold">{{ category }}</div>
            <div class="is-size-5">{{ subtitle }} <span :class="{ 'has-text-danger' : selectedCount > maxSelected }">({{ selectedCount }}/{{ imagesWithInfo.length }})</span></div>
          </div>
          <div class="column is-narrow">
            <div class="buttons">
              <!-- <a class="button tooltip"
                @click="getThumbnails"
                data-tooltip="Refresh">
                <span class="icon">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a> -->
              <a class="button tooltip"
                v-if="imagesWithInfo.length > 0"
                @click="setSelectedStatusAll(true)"
                data-tooltip="Select all">
                <span class="icon has-text-success">
                  <i class="mdi mdi-select-all mdi-24px" />
                </span>
              </a>
              <a v-show="hasSelected"
                class="button tooltip"
                @click="setSelectedStatusAll(false)"
                data-tooltip="Deselect all">
                <span class="icon has-text-grey">
                  <i class="mdi mdi-select-off mdi-24px" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div v-if="isLoadingImages"
          class="columns is-multiline dragable-box">
          <div class="section">
            <div class="is-loading-image-popup content has-text-grey has-text-centered" />
          </div>
        </div>
        <div v-else-if="imagesWithInfo.length === 0"
          class="content has-text-grey is-multiline dragable-box">
          <div class="column is-one-fifth has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <div>No Images</div>
          </div>
        </div>
        <div v-else
          class="columns is-multiline dragable-box">
          <div v-for="(image, index) in imagesWithInfo"
            :key="index"
            class="column is-narrow p-2">
            <div v-if="image.imageContent"
              class="card"
              style="box-shadow: 0 0 0 1px #d9d9d9">
              <header class="card-header-line">
                <!-- <p class="card-header-title">
                  Image title
                </p> -->
                <!-- <p class="card-header-title"
                  style="justify-content: flex-end"> -->
                <div class="card-header-title p-2 has-background-light">
                  <div class="columns is-gapless"
                    style="width: 100%">
                    <div class="column">
                      <div class="field">
                        <div class="control">
                          <div class="pretty p-icon p-curve p-smooth"
                            style="font-size: 0.875rem">
                            <input :id="`chk-pretty-image1${index}`"
                              :name="`chk-pretty-select-image${index}`"
                              v-model="image.isSelected"
                              @change="setSelectedStatus($event, image.quoteImageId)"
                              type="checkbox">
                            <div class="state p-success">
                              <i class="icon mdi mdi-check" />
                              <label />
                            </div>
                          </div>
                          <!-- <input class="is-checkradio is-small is-success"
                            :class="{ 'has-background-color': image.isSelected }"
                            :id="`chk-image-select${index}`"
                            :name="`chk-image-select${index}`"
                            type="checkbox"
                            v-model="image.isSelected">
                          <label :for="`chk-image-select${index}`"
                            data-label-empty /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-image">
                <a @click="viewImage(index)">
                  <figure class="image is-96x96">
                    <img v-if="image"
                      class="image is-96x96"
                      style="object-fit: cover"
                      :src="image.imageContent"
                      :key="image.quoteImageId">
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <bulma-carousel-modal v-if="isCarouselModalActive"
      :image-width="800"
      :image-height="600"
      :active.sync="isCarouselModalActive"
      :image-ids="imageIds"
      :start-index="carouselStartIndex"
      @close="closeCarousel()" />
  </div>
</template>

<script>
// import _cloneDeep from 'lodash/cloneDeep'
import ImageSelectService from './ImageSelectService'
import { BulmaCarouselModal } from '@/components/BulmaCarousel'

const MaximumImages = 99

export default {
  name: 'ImageSelectCategory',
  components: {
    BulmaCarouselModal
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    images: {
      type: Array,
      default: () => []
    },
    quoteId: {
      type: String,
      default: ''
    },
    quoteIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxSelected: {
      type: Number,
      default: MaximumImages
    },
    subtitle: {
      type: String,
      default: 'Select images to attach'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    category: {
      type: String
    },
    categories: {
      type: Array,
      default: () => []
    },
    categoryId: {
      type: String
    },
    categoryOrderIndex: {
      type: Number
    },
    preSelect: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      size: 200,
      imagesWithInfo: [],
      imageIds: null,
      isCarouselModalActive: false,
      carouselStartIndex: 0,
      isLoadingImages: false,
      selectedQuotes: [],
      allImages: []
    }
  },
  computed: {
    hasSelected() {
      return this.imagesWithInfo.some((i) => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function (total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    },
    selected() {
      if (this.imagesWithInfo) {
        return this.imagesWithInfo.map(function (i) {
          return i.isSelected
        })
      }
    }
  },
  watch: {
    isLoadingImages(newVal, oldVal) {
      this.$emit('update:loading-images', newVal)
    },
    images(value) {
      this.getThumbnails()
    },
    imagesWithInfo(value) {
      const imageIds = this.imagesWithInfo.map((i) => i.quoteImageId)
      this.imageIds = imageIds
    }
  },
  created() {
    this.getThumbnails()
    this.selectedQuotes = this.subQuoteNos
  },
  methods: {
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await ImageSelectService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      //this.imageIds = await QuoteService.getImageIdsWithCategory(this.quoteId, this.categoryId)
      if (this.images) {
        const images = this.images.filter((i) => i.imageCategoryId == this.categoryId).sort((a, b) => a.orderIndex - b.orderIndex)
        const noCategoryImages = this.categoryOrderIndex === 0 ? this.images.filter((i) => !i.imageCategoryId) : []
        const combinedImages = images.concat(noCategoryImages).sort((a, b) => a.orderIndex - b.orderIndex)
        combinedImages.forEach((img, index) => {
          img.orderIndex = index
        })
        this.imagesWithInfo = combinedImages
      }
      this.imagesWithInfo.forEach(function (i) {
        const index = vm.images.findIndex((element) => i.quoteImageId === element.quoteImageId)
        const selected = vm.images[index].isSelected
        i.isSelected = selected
      })
      this.isLoadingImages = false
      this.areThumbnailsLoaded = true
      this.applyPreSelect()
    },
    setSelectedStatusAll(selected) {
      this.imagesWithInfo.forEach((i) => (i.isSelected = selected))
      this.imagesWithInfo.forEach((element) => {
        const index = this.images.findIndex((i) => i.quoteImageId === element.quoteImageId)
        this.images[index].isSelected = selected
      })
      //this.$emit('update-images', this.images)
    },
    setSelectedStatus(event, imageId) {
      const selected = event.target.checked
      const index = this.imagesWithInfo.findIndex((i) => i.quoteImageId === imageId)
      this.imagesWithInfo[index].isSelected = selected
      const index1 = this.images.findIndex((i) => i.quoteImageId === imageId)
      this.images[index1].isSelected = selected
      //this.$emit('update-images', this.images)
    },
    async viewImage(index) {
      this.$showSpinner()
      this.carouselStartIndex = index
      this.isCarouselModalActive = true
      this.$hideSpinner()
    },
    closeCarousel() {
      this.isCarouselModalActive = false
    },
    getSelectedQuoteImages() {
      if (this.allImages.length !== 0) {
        const selectedQuoteImages = []
        for (var key in this.selectedQuotes) {
          for (let i = 0; i < this.allImages.length; i++) {
            const currentImage = this.allImages[i]
            if (currentImage.quoteId === this.selectedQuotes[key].value) selectedQuoteImages.push(this.allImages[i])
          }
        }
        this.imagesWithInfo = selectedQuoteImages
        this.setSelectedStatusAll(true)
      }
    },
    applyPreSelect() {

    }
  }
}
</script>

<style lang="scss" scoped>
.card-header-title {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem !important;
}

.is-multiline.dragable-box {
  min-height: 3em;
  border: dashed 2px #e3e2e2;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 3px;
  background-color: #fafafa;
}
</style>
